import React, { useState, useEffect } from "react"
import SmallDropdown from "../../components/SmallDropdown"
import Backend from "../../utility/Backend"
import "./ShareVideo.css"

function CaptionOptions({ caption, video, onCaptionGenerated, platform }) {
    const [tone, setTone] = useState("neutral")
    const [teamFavor, setTeamFavor] = useState("")
    const [rivalry, setRivalry] = useState("neutral")
    const [lengthPreference, setLengthPreference] = useState("medium")
    const [language, setLanguage] = useState("English")
    const [generatedCaption, setGeneratedCaption] = useState("")
    const [error, setError] = useState(null)

    const onGenerateCaption = async () => {
        const requestBody = {
            platform: platform.toLowerCase(),
            user_description: caption.toLowerCase(),
            tone: tone.toLowerCase(),
            team_favor: teamFavor.toLowerCase(),
            rivalry: rivalry.toLowerCase(),
            original_title: (video.description || "Video Title").toLowerCase(),
            length_preference: lengthPreference.toLowerCase(),
            language: language.toLowerCase()
        }
    
        try {
            const response = await Backend.post("/socials/generate_post_description", JSON.stringify(requestBody))
            if (response.data.description) {
                setGeneratedCaption(response.data.description)
                onCaptionGenerated(response.data.description)
            } else {
                setError("Failed to generate caption")
            }
        } catch (error) {
            console.error("Error generating caption:", error)
            setError("Failed to generate caption")
        }
    }
    

    return (
        <div className="caption-options">
            <div className="caption-options-dropdown">
                <div className="input-row-caption">
                    <div className="input-container-caption">
                        <label className="input-title">Platform</label>
                        <SmallDropdown
                            value={platform}
                            options={[{ value: platform, label: platform }]}
                            disabled={true}
                        />
                    </div>
    
                    <div className="input-container-caption">
                        <label className="input-title">Team Favor</label>
                        <input
                            type="text"
                            value={teamFavor}
                            onChange={(e) => setTeamFavor(e.target.value)}
                            placeholder="Enter team name"
                            className="team-favor-input"
                        />
                    </div>
                </div>
                <div className="input-row-caption">
                    <div className="input-container-caption">
                        <label className="input-title">Rivalry</label>
                        <SmallDropdown
                            value={rivalry}
                            options={[
                                { value: "neutral", label: "Neutral" },
                                { value: "peaceful", label: "Peaceful" },
                                { value: "rivalry", label: "Rivalry" }
                            ]}
                            onChange={(selectedOption) => setRivalry(selectedOption.value)}
                        />
                    </div>
    
                    <div className="input-container-caption">
                        <label className="input-title">Tone</label>
                        <SmallDropdown
                            value={tone}
                            options={[
                                { value: "neutral", label: "Neutral" },
                                { value: "formal", label: "Formal" },
                                { value: "casual", label: "Casual" },
                                { value: "exciting", label: "Exciting" },
                                { value: "humorous", label: "Humorous" }
                            ]}
                            onChange={(selectedOption) => setTone(selectedOption.value)}
                        />
                    </div>
                </div>
                <div className="input-row-caption">
                    <div className="input-container-caption">
                        <label className="input-title">Length Preference</label>
                        <SmallDropdown
                            value={lengthPreference}
                            options={[
                                { value: "short", label: "Short" },
                                { value: "medium", label: "Medium" },
                                { value: "long", label: "Long" }
                            ]}
                            onChange={(selectedOption) => setLengthPreference(selectedOption.value)}
                        />
                    </div>
    
                    <div className="input-container-caption">
                        <label className="input-title">Language</label>
                        <SmallDropdown
                            value={language}
                            options={[
                                { value: "English", label: "English" },
                                { value: "Norwegian", label: "Norwegian" },
                                { value: "Swedish", label: "Swedish" },
                            ]}
                            onChange={(selectedOption) => setLanguage(selectedOption.value)}
                        />
                    </div>
                </div>
            </div>
    
            <button onClick={onGenerateCaption} className="generate-caption-btn">Generate</button>
    
            {error && <div className="error-message">{error}</div>}
        </div>
    )
    
}

export default CaptionOptions
