import React, { useEffect, useState } from "react"
import Backend from "../../utility/Backend"
import Config from "../../utility/Config"
import CustomModal from "../../components/CustomModal"
import SmallDropdown from "../../components/SmallDropdown"
import { useLoginSession } from "../../stores/loginSession"
import { showErrorMessage } from "../../utility/Utilities"
import classNames from "classnames"
import { FaTiktok } from "react-icons/fa"
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md"
import "./ShareVideo.css"
import CaptionOptions from "./CaptionOptions"

function ShareToTiktok ({ shareVideo, onClose, openShareTable }) {
    const { token } = useLoginSession()
    const video = shareVideo.video

    const [tiktokUser, setTiktokUser] = useState(null)
    const [loggingOutTiktok, setLoggingOutTiktok] = useState(false)
    const [isLoadingCredentials, setIsLoadingCredentials] = useState(true)
    const [title, setTitle] = useState("")
    const [privacy_level, setPrivacyLevel] = useState(null)
    const [disable_comment, setDisableComment] = useState(false)
    const [disable_duet, setDisableDuet] = useState(false)
    const [disable_stitch, setDisableStitch] = useState(false)
    const [video_cover_timestamp_ms, setVideoCoverTimestampMs] = useState(null)
    const [error, setError] = useState(null)
    const [privacyOptions, setPrivacyOptions] = useState([])
    const [isCommercialContent, setIsCommercialContent] = useState(false)
    const [yourBrand, setYourBrand] = useState(false)
    const [brandedContent, setBrandedContent] = useState(false)

    const [isCaptionOptionsVisible, setIsCaptionOptionsVisible] = useState(false)
    const [generatedCaption, setGeneratedCaption] = useState("") 

    const fetchTiktokCredential = async () => {
        try {
            const { data: tiktokCredentials } = await Backend.get("/socials/credentials/tiktok")
            if (tiktokCredentials && tiktokCredentials.length > 0) {
                const credential = tiktokCredentials[0]
                setTiktokUser(credential)
                setPrivacyOptions(credential.privacy_level_options)
            } else {
                setTiktokUser(null)
            }
        } catch (error) {
            console.error("Failed to fetch TikTok credentials", error)
        } finally {
            setIsLoadingCredentials(false)
        }
    }

    useEffect(() => {
        fetchTiktokCredential()
    }, [])

    useEffect(() => {
        const fetchInterval = setInterval(() => {
            if (isLoadingCredentials) fetchTiktokCredential()
        }, 3000)
        return () => clearInterval(fetchInterval)
    }, [isLoadingCredentials])

    useEffect(() => {
        if (isCommercialContent && !yourBrand && !brandedContent) {
            setError("Please select either your brand or branded content for commercial content.")
        } else {
            setError(null)
        }
    }, [isCommercialContent, yourBrand, brandedContent])

    const openTiktokLogin = () => {
        const leaguePath = window.location.pathname.split("/")[1]
        const tiktokLoginUrl = `${Config.api}${leaguePath}/socials/credentials/tiktok/authorize?access_token=${token}`
        const { outerWidth: screenWidth, outerHeight: screenHeight, screenX, screenY } = window
        const [windowWidth, windowHeight] = [750, 750]
        const left = screenX + (screenWidth - windowWidth) / 2
        const top = screenY + (screenHeight - windowHeight) / 2
    
        const popup = window.open(tiktokLoginUrl, "_blank", `width=${windowWidth}, height=${windowHeight}, left=${left}, top=${top}`)
        setIsLoadingCredentials(true)
    
        const popupInterval = setInterval(() => {
            if (popup && popup.closed) {
                clearInterval(popupInterval)
                fetchTiktokCredential()
            }
        }, 500)
    }

    const resetTiktokShare = () => {
        setTiktokUser(null)
        setTitle("")
        setPrivacyLevel(null)
        setDisableComment(false)
        setDisableDuet(false)
        setDisableStitch(false)
        setVideoCoverTimestampMs(null)
        setLoggingOutTiktok(false)
        setError(null)
        setIsLoadingCredentials(false)
        setIsCommercialContent(false)
        setYourBrand(false)
        setBrandedContent(false)
    }

    const onShareToTiktok = async () => {
        if (!tiktokUser?.tiktok_id || !privacy_level || (isCommercialContent && (!yourBrand && !brandedContent))) {
            setError("Please complete the form correctly.")
            return
        }

        if (video.duration > tiktokUser.max_video_post_duration_sec) {
            setError(`The video duration exceeds the maximum allowed duration of ${tiktokUser.max_video_post_duration_sec / 60} minutes.`)
            return
        }

        const shareToTiktokBody = {
            media_type: "VIDEO",
            video_url: video.download_link,
            title: generatedCaption || title,
            privacy_level: privacy_level,
            disable_comment: !disable_comment,
            disable_duet: !disable_duet,
            disable_stitch: !disable_stitch,
            video_cover_timestamp_ms: video_cover_timestamp_ms || 0,
        }

        try {
            const response = await Backend.post("/socials/share/tiktok/" + tiktokUser?.tiktok_id, JSON.stringify(shareToTiktokBody))
            if (response.error) {
                setError(response.error)
                console.error("Failed to post", response.error)
            } else {
                onClose()
                openShareTable()
            }
        } catch (error) {
            setError(error.message || "An unknown error occurred")
            console.error("Failed to post", error)
        }
    }

    const onLogoutTiktok = async () => {
        try {
            await Backend.delete("/socials/credentials/tiktok/" + tiktokUser?.tiktok_id)
            resetTiktokShare()
        } catch (error) {
            setError(error)
            console.error("Failed to logout", error)
        }
    }

    const handlePrivacyChange = (value) => {
        if (value === "SELF_ONLY") {
            setPrivacyLevel("SELF_ONLY")
            setBrandedContent(false)
        } else {
            setPrivacyLevel(value)
            setError(null)
        }
    }

    const handleTitleChange = (e) => {
        setTitle(e.target.value)
    }

    const tiktokUserDetails = (
        <div className="user-logged-in">
            <div className="logged-in-title">Logged in as {tiktokUser?.nickname}</div>
            {tiktokUser ? (
                <div className="share-user-profile">
                    <div className="user-profile-and-name">
                        <img src={tiktokUser.tiktok_picture_url} alt="profile img" />
                        <div>{tiktokUser.tiktok_nickname}</div>
                        <button onClick={() => setLoggingOutTiktok(true)} className="small-btn red-btn">Logout</button>
                    </div>
                    {loggingOutTiktok && (
                        <div className="confirm-logout show">
                            <div className="create-delete-credentials-title">Confirm logout?</div>
                            <div className="delete-credentials-btn">
                                <button onClick={onLogoutTiktok} className="red-btn small-btn">Logout</button>
                                <button onClick={() => setLoggingOutTiktok(false)} className="small-btn">Cancel</button>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <>
                    <div className="login-or-create-credentials">
                        <button onClick={openTiktokLogin} className="login-with-tiktok-btn">
                            <FaTiktok />
                            Login with TikTok
                        </button>
                    </div>
                </>
            )}
            {error && showErrorMessage("Please login to share", !tiktokUser)}
            {error && <div className="share-login-error">{error}</div>}
        </div>
    )

    const shareToTiktokForm = (
        <div className="share-video-form">
            <div className="input-container">
                <label className="input-title">Title</label>
                <textarea
                    onChange={handleTitleChange}
                    value={title}
                    className="share-video-title-input"
                    maxLength="2200"
                />
                <span className="char-count-twitter">{2200 - title.length}</span>
                <button 
                    onClick={() => setIsCaptionOptionsVisible(!isCaptionOptionsVisible)} 
                    className="generate-caption-btn">
                    {isCaptionOptionsVisible ? "Hide Options" : "Generate Caption"}
                </button>
            </div>

            {isCaptionOptionsVisible && (
                <CaptionOptions 
                    caption={title} 
                    video={video}
                    onCaptionGenerated={(generatedCaption) => setTitle(generatedCaption)} 
                    platform={"TikTok"}
                />
            )}

            <div className="input-container">
                <label className="input-title">Who can view this video</label>
                <div className="tooltip">
                    <SmallDropdown
                        value={privacy_level}
                        options={privacyOptions.map(option => (
                            option.value === "SELF_ONLY" && isCommercialContent && brandedContent
                                ? { ...option, disabled: true, className: "greyed-out" }
                                : option
                        ))}
                        onChange={handlePrivacyChange}
                        placeholder="Select Privacy Level"
                    />
                    {isCommercialContent && brandedContent && (
                        <span className="tooltiptext">Branded content visibility cannot be set to private.</span>
                    )}
                </div>
            </div>
            <div className="input-container">
                <label className="input-title">Allow users to</label>
                <div className="checkbox-container">
                    <div
                        className={classNames("interaction-checkbox", { "greyed-out": tiktokUser?.comment_disabled })}
                        onClick={() => !tiktokUser?.comment_disabled && setDisableComment(!disable_comment)}
                    >
                        {disable_comment ? (
                            <MdOutlineCheckBox className="checkbox-icon" />
                        ) : (
                            <MdOutlineCheckBoxOutlineBlank className="checkbox-icon" />
                        )}
                        Allow Comment
                    </div>
                    {video.media_type !== "PHOTO" && (
                        <>
                            <div
                                className={classNames("interaction-checkbox", { "greyed-out": tiktokUser?.duet_disabled })}
                                onClick={() => !tiktokUser?.duet_disabled && setDisableDuet(!disable_duet)}
                            >
                                {disable_duet ? (
                                    <MdOutlineCheckBox className="checkbox-icon" />
                                ) : (
                                    <MdOutlineCheckBoxOutlineBlank className="checkbox-icon" />
                                )}
                                Allow Duet
                            </div>
                            <div
                                className={classNames("interaction-checkbox", { "greyed-out": tiktokUser?.stitch_disabled })}
                                onClick={() => !tiktokUser?.stitch_disabled && setDisableStitch(!disable_stitch)}
                            >
                                {disable_stitch ? (
                                    <MdOutlineCheckBox className="checkbox-icon" />
                                ) : (
                                    <MdOutlineCheckBoxOutlineBlank className="checkbox-icon" />
                                )}
                                Allow Stitch
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className={classNames("input-container", { "greyed-out": privacy_level === "SELF_ONLY" })}>
                <div className="switch-container">
                    <label className="input-title">Disclose video content</label>
                    <label className="switch">
                        <input 
                            type="checkbox" 
                            checked={isCommercialContent} 
                            onChange={() => setIsCommercialContent(!isCommercialContent)} 
                            disabled={privacy_level === "SELF_ONLY"} 
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
                <p>
                    Turn on to disclose that this video promotes goods or services in
                    exchange for something of value. Your video could promote
                    yourself, a third party, or both.
                </p>

                {isCommercialContent && (
                    <div>
                        <div
                            className={classNames("interaction-checkbox", { "greyed-out": privacy_level === "SELF_ONLY" })}
                            onClick={() => privacy_level !== "SELF_ONLY" && setYourBrand(!yourBrand)}
                        >
                            {yourBrand ? (
                                <MdOutlineCheckBox className="checkbox-icon" />
                            ) : (
                                <MdOutlineCheckBoxOutlineBlank className="checkbox-icon" />
                            )}
                            Your Brand
                            {yourBrand && (
                                <div className="prompt">
                                    Your video will be labeled as <span style={{ fontWeight: "bold" }}>Promotional content</span>
                                </div>
                            )}
                        </div>
                        <div
                            className={classNames("interaction-checkbox", { "greyed-out": privacy_level === "SELF_ONLY" })}
                            onClick={() => privacy_level !== "SELF_ONLY" && setBrandedContent(!brandedContent)}
                        >
                            {brandedContent ? (
                                <MdOutlineCheckBox className="checkbox-icon" />
                            ) : (
                                <MdOutlineCheckBoxOutlineBlank className="checkbox-icon" />
                            )}
                            Branded Content
                            {brandedContent && (
                                <div className="prompt">
                                    Your video will be labeled as <span style={{ fontWeight: "bold" }}>Paid partnership</span>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="declaration">
                <p>
                    {isCommercialContent && brandedContent
                        ? "By posting, you agree to TikTok's Branded Content Policy and Music Usage Confirmation."
                        : isCommercialContent && yourBrand
                            ? "By posting, you agree to TikTok's Music Usage Confirmation."
                            : "By posting, you agree to TikTok's Music Usage Confirmation."}
                </p>
            </div>
        </div>
    )

    return (
        <CustomModal isOpen={!!shareVideo} onRequestClose={onClose} className="share-modal">
            <div className="share-video-modal">
                <div className="share-video-title">Share video to Tiktok</div>
                <div className="share-video-and-credentials">
                    <div className="share-video-review">
                        <video src={video.download_link} controls></video>
                    </div>
                    <div className="credentials-and-form">
                        {tiktokUserDetails}
                        {shareToTiktokForm}
                        <div className="confirm-cancel-btn-cont right">
                            <button onClick={onShareToTiktok} disabled={!!error} className="share-to-social-media-btn green-btn">
                                Share to Tiktok
                            </button>
                            <button onClick={onClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default ShareToTiktok
