import {useCompilation} from "../../../../stores/compilation";
import DirectDownload from "../../../../components/DirectDownload";
import React from "react";
import {EDIT_MODE_CLIP, EDIT_MODE_COMPILATION, EDIT_MODE_SUBCLIP, EDIT_MODE_EXPORT, EDIT_MODE_SUBTITLES} from "../PlaylistMetadataSection";
import { useFeedbackMessage } from "../../../../stores/FeedbackMessage";
import {useLoginSession} from "../../../../stores/loginSession";
import PrepareExport from "./PrepareExport";
import "./EditingControls.css";
import { RiScissorsFill } from "react-icons/ri";
import { CgExport } from "react-icons/cg";
import { MdVideoLibrary } from "react-icons/md";
import { ImInfo } from "react-icons/im";

export default function VideoEditButtons ({
    playlist,
    isCompilation,
    setMode,
    checkIsEditingCompilation,
    openNoPermissionModal,
    hasEditPermission=false,
    hasSubclipPermission = false,
}) {

    const {initializeCompilation, clips} = useCompilation();
    const {showFeedback} = useFeedbackMessage();
    const {allowVideoExport} = useLoginSession()

    const editClip = () => {
        if (!hasEditPermission) openNoPermissionModal()
        else setMode(EDIT_MODE_CLIP)
    }

    const editSubClip = () => {
        if (!hasSubclipPermission) openNoPermissionModal()
        else setMode(EDIT_MODE_SUBCLIP)
    }

    const exportClip = () => {
        setMode(EDIT_MODE_EXPORT)
    }

    const openSubtitles = () => {
        setMode(EDIT_MODE_SUBTITLES)
    }

    function startEditingCompilation () {
        if (clips.length > 0) {
            checkIsEditingCompilation();
        } else {
            initializeCompilation(playlist);
            setMode(EDIT_MODE_COMPILATION);
            showFeedback("success", "Video is available for editing");
        }
    }

    // TODO temporary check for a full match (asset) video 
    const isFullMatch = playlist.duration_ms > 3600000
    const hasAsset = !!(playlist.master_videoasset)
    const assetId = playlist.master_videoasset?.id || playlist.events[0]?.video_asset_id

    const buttons = isCompilation ? (
        <div className="video-editing-controls-btn">
            <button onClick={startEditingCompilation}>
                Edit compilation
            </button>
            <DirectDownload
                filename={`Compilation_${playlist.id}`}
                videoUrl={playlist.video_url}
                duration={playlist.duration_ms/1000}
                />
            <PrepareExport playlist={playlist}/>
        </div>
    ) : (
        <div className="video-editing-controls-btn">  
            {(!hasAsset && !isFullMatch) && (
                <button onClick={editClip}>
                    <RiScissorsFill className="icon-in-btn"/>
                    Adjust video interval
                </button>
            )}
            <button onClick={editSubClip}>
                <MdVideoLibrary className="icon-in-btn"/>
                Create subclip
            </button>
            {allowVideoExport && (
                <button onClick={exportClip}>
                    <CgExport className="icon-in-btn"/>
                    Export
                </button>
            )}
            {/* {!!assetId && (
                <button onClick={openSubtitles}>
                    <CgExport className="icon-in-btn"/>
                    Subtitles
                </button>
            )}
        </div>
            )} */}
        </div>
    );

    const downloadTips = isCompilation && (
        <div className="download-tips">
            <div className="download-tips-icon">
                <ImInfo/>
            </div>
            <div>
                <div>Direct download is faster, but supported formats may not be suitable for all purposes. If one does not work, try another. </div>
                <div>If you need any transformations or an all-purpose .mp4, use Prepared Export.</div>
            </div>
        </div>
    )

    return (
        <div>
            {buttons}
            {downloadTips}
        </div>
    );
}